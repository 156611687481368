@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Roboto Condensed", sans-serif;
}
#background {
  background-image: url("../src/assets/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Optional: This keeps the background fixed while scrolling */
  width: 100%; /* Sets the width to 100% of the container's width */
}
